<template>
  <div style="background: #3291FF;height: 1rem;width: 100%;display: flex;flex-direction: row;">
    <div style="width: 25%;height: 100%;color: white;font-size: 0.3rem;position: relative" class="center">
      <el-tooltip class="item" effect="dark" content="拖动LOGO 即刻收藏" placement="bottom">
        <a style="display: flex;align-items: center;width: 2rem;height: 0.5rem;color: white;justify-content: space-around;cursor: pointer"
           href="http://jkt.lanshuo.org.cn/index"
           @mouseenter="tishi" @mouseleave="show=false">
          <img src="../assets/img/logo.png" style="width: 0.5rem;height: 0.5rem">
          蓝硕科技
        </a>
      </el-tooltip>


      <div style="min-width: 0.1rem;height: 0.6rem;position: absolute;left: 67%;top: 0.05rem;transition: all 0.3s;"  :style="show===true?'opacity: 1':'opacity: 0'">
        <img src="../assets/img/jianto.svg">
      </div>

    </div>
    <div style="width: 40%;height: 100%;display: flex;flex-direction: row;-webkit-user-select: none">
      <div class="center titText"
           v-for="(it,index) in list"
           :key="index" @click="cli(index)"
           :class="a===index?'bg1':'bg2'"> {{ it.va }} </div>
    </div>
    <div style="width: 35%;height: 100%;display: flex;flex-direction: row;">

      <div style="width: 40%;height: 100%;display: flex;margin-left: 5%" class="center">
        <el-input
            placeholder="请输入内容"
            v-model="input">
          <i slot="suffix" class="el-input__icon el-icon-search" style="cursor: pointer;font-size: 16px" @click="search"></i>
        </el-input>
      </div>

      <div v-if="loginOrHeadShow===1" style="width: 20%;height: 100%;font-size: 0.2rem;color: white;cursor: pointer" class="center" @click="toLogin">登录</div>
      <div v-if="loginOrHeadShow===0" style="width: 25%;height: 100%;" class="center" >
        <el-popover
            placement="top-start"
            width="100"
            trigger="hover">
          <div class="center" style="flex-direction: column;width: 100%">
<!--            个人电话号码，先注释，要不然还要在state定义值-->
            <div style="color: #3291FF;font-size: 0.175rem;cursor: pointer;line-height: 30px" @click="toPersonal">
<!--              {{userPhone}}-->个人中心
            </div>
            <div style="border-top: 1px solid #EBEBEB;font-size: 0.175rem;cursor: pointer;width: 100%;line-height: 30px" class="center" @click="exitLogin">退出登录</div>
          </div>

          <div slot="reference" style="display: flex;color: white;align-items: center" @click="toPersonal">
<!--            <img :src="headPath" draggable="false" style="cursor: pointer;width: 36px;height: 36px;border-radius: 50%;margin-right: 0.1rem" @click="toPersonal">-->
            <img src="../assets/img/tx.svg" draggable="false" style="cursor: pointer;width: 36px;height: 36px;border-radius: 50%;margin-right: 0.1rem" >
            <div style="font-size: 0.2rem;cursor: pointer">{{ $store.state.info.name }}</div>
          </div>
        </el-popover>
      </div>
      <el-tooltip class="item" effect="dark" content="拖动LOGO 即刻收藏" placement="bottom">
        <div style="width: 30%;height: 100%;color: white;font-size: 0.2rem;cursor: default"  class="center"  @mouseenter="tishi" @mouseleave="show=false">
          收藏本站
        </div>
      </el-tooltip>
    </div>
<!--登录弹窗-->
    <el-dialog
        :visible="loginShow"
        width="30%"
        :before-close="handleClose">
      <div style="width: 75%;margin: auto;margin-top: -5%;min-height: 2rem;display: flex;flex-direction: column">
        <div style="width: 100%;font-size: 0.225rem;display: flex;" :class="loginOrRegType===0||loginOrRegType===1?'c':'center'">
          <div style="cursor: pointer" @click="qieH(0)" :class="loginOrRegType===0?'txt1':'txt2'" v-if="loginOrRegType===0||loginOrRegType===1">账号密码登录</div>
          <div style="cursor: pointer" @click="qieH(1)" :class="loginOrRegType===1?'txt1':'txt2'" v-if="loginOrRegType===0||loginOrRegType===1">手机验证码登录</div>
          <div style="color: black" v-if="loginOrRegType===2">注册账号</div>
          <div style="color: black" v-if="loginOrRegType===3">忘记密码</div>
        </div>

        <div style="min-height: 2.5rem;">
          <z-hm-m @ZHmM="xxxToLog" v-if="loginOrRegType===0" ref="loginData"></z-hm-m>
          <s-jy-z @sJyZ="xxxToLog" v-if="loginOrRegType===1" ref="codeLoginData"></s-jy-z>
          <z-cz-h @zCzH="xxxToLog" v-if="loginOrRegType===2" ref="regData"></z-cz-h>
          <w-jm-m @wJmM="xxxToLog" v-if="loginOrRegType===3"></w-jm-m>
        </div>

        <div style="width: 100%">
          <div style="width: 100%;font-size: 0.175rem;color: #3291FF;" :class="loginOrRegType===0||loginOrRegType===1?'c':'c2'">
            <div style="cursor: pointer" @click="qieH(2)" v-if="loginOrRegType===0||loginOrRegType===1">新用户注册</div>
            <div style="cursor: pointer" @click="qieH(3)" v-if="loginOrRegType===0||loginOrRegType===1">忘记密码？</div>
            <div style="cursor: pointer" @click="qieH(0)" v-if="loginOrRegType===2||loginOrRegType===3">去登录</div>
          </div>
        </div>
      </div>
      <div style="width: 75%;margin: auto;margin-top: 3%">
        <el-button type="primary" @click="toLoginOrReg" style="width: 100%">
          {{loginOrRegType===0||loginOrRegType===1?'登录':loginOrRegType===2?'注册':loginOrRegType===3?'确认':''}}
        </el-button>
      </div>
    </el-dialog>
<!--   ----------------------- -->
  </div>
</template>

<script>
import ZHmM from "@/components/login/accountPassword";
import SJyZ from "@/components/login/mobilePhoneVerification";
import ZCzH from "@/components/login/signUp";
import WJmM from "@/components/login/forgotPassword";
import {mapState,mapMutations} from "vuex";
// 引入定义的请求接口
import {allUrl} from "@/service/request.js";
export default {
  name: "topPage",
  components: {WJmM, ZCzH, SJyZ, ZHmM},
  data(){
    return{
      list:[
        {va:'首页'},
        {va:'课程中心'},
        {va:'在线考试'},
        {va:'在线练习'},
        {va:'成功案例'},
        {va:'APP下载'},
        {va:'联系我们'},
      ],
      a:0,//标题选择判断
      input:'',
      loginOrRegType:0,//012账号密码登录 手机验证码登录 注册
      ro:'',
      ro2:'',
      show:false
    }
  },
  computed:{
    ...mapState(['loginShow','headPath','loginOrHeadShow','userPhone'])
  },
  methods:{
    xxxToLog(){//摁回车触发
      this.toLoginOrReg()
    },
    tishi() {
      this.show=true
    },
    ...mapMutations(['setLoginShow','setHeadPath','setLoginOrHeadShow','setUserPhone']),
    cli(val){
      const token = window.localStorage.getItem("userToken")
      console.log("============================")
      console.log(val)
      console.log(token)
      // console.log('this.ro')
      // console.log(this.ro)
      // console.log(this.ro2)
      if (this.ro2===''||this.ro2===this.ro){
        console.log('未跳转')

      }else
        this.a=val

      this.$emit('cli',val)
      if (val===2&&window.localStorage.getItem("userToken")===null){
        this.$confirm('请先登录', '请登录', {
          confirmButtonText: '登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // this.$message({
          //   type: 'success',
          //   message: '删除成功!'
          // });
          this.setLoginShow(true);
        }).catch(() => {
          this.$router.go(-1)
        });

      }
    },
    open(val){
      this.show=val
      this.loginOrRegType=0
      return this.loginOrRegType
    },
    toLogin(){
      this.setLoginShow(true);
      this.loginOrRegType=0;
    },
    handleClose() {
      this.setLoginShow(false);
      if (this.a===2){
        this.$router.go(-1)
      }
    },
    qieH(val){

      this.loginOrRegType=val
      return this.loginOrRegType
    },
    // 登录还是注册
    toLoginOrReg(){
      console.log(this.loginOrRegType);//012账号密码登录 手机验证码登录 注册

      if(this.loginOrRegType==0){
        this.loginFunction();
      }else if(this.loginOrRegType==1){
        this.codeLoginFunction();
      }else if(this.loginOrRegType==2){
        console.log("注册");
        this.regFunction();
      }

    },
    codeLoginFunction(){
      console.log("手机验证码登录");

      this.request.post("/user/loginByTelCode", {
        SMS_code:this.$refs.codeLoginData.$data.input2,
        tel:this.$refs.codeLoginData.$data.input
      }).then(res => {
        console.log(res)
        if(res.status=="success"){
          window.localStorage.setItem("userToken",res.token);
          // 获取用户信息
          this.getUserInfo();
          this.setLoginShow(false);
          this.setLoginOrHeadShow(0);
        }else{
          this.$alert('验证码输入错误。', '提示', {
            confirmButtonText: '确定',
            callback: action => {
              console.log(action)
            }
          });
        }
      })
    },
    // 登录方法
    loginFunction(){
      console.log(this.$refs.loginData.$data.phoneInput)
      console.log(this.$refs.loginData.$data.passwordInput)

      // 手机正则
      // this.validateTelephone(ZHmM.data().phoneInput);
      if(this.$refs.loginData.$data.phoneInput=="" || this.$refs.loginData.$data.phoneInput.length!=11){
        this.$alert('请输入正确的手机号。', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            console.log(action)
          }
        });
        return;
      }
      if(this.$refs.loginData.$data.passwordInput==""){
        this.$alert('请输入正确的密码。', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            console.log(action)
          }
        });
        return;
      }

      this.request.post("/user/login", {
        login_name:this.$refs.loginData.$data.phoneInput,
        login_password:this.$refs.loginData.$data.passwordInput
      }).then(res => {
        console.log(res)
        if(res.status=="success"){
          window.localStorage.setItem("userToken",res.token);
          // 获取用户信息
          this.getUserInfo();
          this.setLoginShow(false);
          this.setLoginOrHeadShow(0);
          this.$router.go(0)//刷新当前页面
        }else{
          this.$alert('账号密码错误。', '提示', {
            confirmButtonText: '确定',
            callback: action => {
              console.log(action)
            }
          });
        }
      })
    },
    regFunction(){
      // 手机号input input2密码 input3验证码

      this.request.post("/user/register", {
        SMS_code: this.$refs.regData.$data.input3,
        tel: this.$refs.regData.$data.input,
        login_password:this.$refs.regData.$data.input2
      }).then(res => {
        console.log(res)
        if (res.status === "success") {
          this.$message({
            type: 'success',
            message: "用户注册成功。"
          });
        }else{
          this.$message({
            type: 'error',
            message: res.data.result
          });
        }
      })

    },
    // 获取用户信息
    getUserInfo(){
      this.request.get("/user/info", {
        params: {
          token:window.localStorage.getItem("userToken")
        }
      }).then(res => {
        if(res.status==="success"){
          console.log("user info")
          console.log(res.data.info);
          this.$store.state.info = res.data.info;
          // 用户信息
          this.setHeadPath(allUrl.picUrl+this.$store.state.headPath);
          this.setLoginOrHeadShow(0);
          this.setUserPhone(res.data.info.tel);
          if(res.data.info.head_img_path===null){
            this.setHeadPath("https://jktcloud.oss-cn-hangzhou.aliyuncs.com/head.png");
          }
          else{
            this.setHeadPath(allUrl.picUrl+res.data.info.head_img_path);
          }
        }
      })
    },
    // 手机正则
    validateTelephone (value) {
      // 1、以1开头
      // 2、第二位数字为3，4，5，6，7，8，9中一个
      // 3、第三位到十一位数字为0到9的任意一个数字
        const reg = /^1[3-9]\d{9}$/
        if (!reg.test(value)) {
          this.$message({
            type: 'warning',
            message: '请输入正确的手机号码!'
          });
          return;
        }
      },
    exitLogin(){
      this.$confirm('是否退出账号', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        window.localStorage.removeItem("userToken");
        this.setLoginOrHeadShow(1);
        // this.$message({
        //   type: 'success',
        //   message: '退出成功!'
        // });
        this.$router.push({
          path:'/index'
        })
      }).catch(() => {

      });
    },
    toCollect(){
      // window.external.AddSearchProvider('http://www.hwedus.com','惠文教育')
      console.log('收藏成功')
      this.$message({
        type: 'success',
        message: '收藏成功!'
      });
    },
    toPersonal(){
      this.a=-1
      this.$router.push({
        path:'/personalCenter'
      })
    },
    search(){
      if (this.input===''){
        // this.$router.go(0)
        console.log('无内容')
      }else if(this.input!==''){
        // console.log(this.input)
          // this.$router.go(0)
          console.log(this.input)
          this.$router.push({
            path:'/search',
            query:{
              txt:this.input
            }
          })
          this.input=''

      }
    }
  },
  mounted() {
    console.log("ddddddddddddddddddddd")
    // 页面刷新回到首页
    // this.cli(0)
    console.log(this.$route.meta.permission)
    if(this.$route.meta.permission===undefined){
      return;
    }
    this.ro=this.$route.meta.permission
    // let tt
    // tt =
    //     setInterval(function (){
    //       this.ro = this.$route.meta.permission
    //   if (this.a!==2){
    //     if (this.ro==='onlineTest'){
    //       this.a=2
    //
    //     }
    //   }
    // },500)

    switch (this.ro) {
      case 'index':
        console.log(0)
          this.a=0
            break
      case 'courseCenter':
        console.log(1)
          this.a=1
            break
      case 'onlineTest':
        console.log(2)
            this.a=2
            break
      case 'onlinePractice':
        console.log(3)
            this.a=3
            break
      case 'personalCenter':
        console.log(-1)
            this.a=-1
            break
      case 'search':
        this.a=-1
            break
      case 'cooperationCases':
        console.log(4)
            this.a = 4
            break
      case 'appDownload':
        console.log(5)
            this.a = 5
            break
      case 'contactUs':
        console.log(6)
            this.a = 6
            break
    }
  },
  watch:{
    // a(tmp,temp){   //注释解决F5刷新页面后 导航栏会一直卡在在线考试 无法离开的问题
    //   if(tmp!==temp){
    //     if (this.ro==='onlineTest'){
    //       this.a=2
    //     }
    //   }
    // },
    $route(t,f){
      console.log(t)
      this.ro2=f.meta.permission
      switch (t.meta.permission) {
        case 'index':
          console.log(0)
          this.a=0
          break
        case 'courseCenter':
          console.log(1)
          this.a=1
          break
        case 'onlineTest':
          console.log(2)
          this.a=2
          break
        case 'onlinePractice':
          console.log(3)
          this.a=3
          break
        case 'personalCenter':
          console.log(-1)
          this.a=-1
          break
        case 'search':
          this.a=-1
          break
        case 'cooperationCases':
          console.log(4)
          this.a = 4
          break
        case 'appDownload':
          console.log(5)
          this.a = 5
          break
        case 'contactUs':
          console.log(6)
          this.a = 6
          break
      }

    }
  }
}
</script>

<style scoped>
.center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.c{
  justify-content: space-between;
  display: flex;
}
.c2{
  display: flex;
  flex-direction: row-reverse;
}
.titText{
  width: 14.5%;cursor: pointer;font-size: 0.2rem;font-weight: 500;transition: all 0.3s;
}
.titText:hover{
  background: white;
  color: #3291FF;
}
.bg1{
  background: white;
  color: #3291FF;
}
.bg2{
  color: white;
}
::v-deep .el-input__inner{
  border-radius: 20px;
}
.txt1{
  color: #3291FF;
}
.txt2{
  color: #999999;
}
</style>
