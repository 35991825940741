import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import './plugins/element.js'
// eslint-disable-next-line no-undef
Vue.prototype.request = request  //全局注册request，其他文件可通过this.request使用分装好的request实例
Vue.prototype.$bus = new Vue()

Vue.use(ElementUI)
Vue.use(router)

Vue.config.productionTip = false
import './assets/allin.css'
import './assets/flexible.js'
import request from "@/service/request";

// 这里就可以进行vue-router的beforeEach拦截了
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '';
  // 这里先获取下用户信息，我偷懒用sessionStorage存了
  // 里面包含了用户权限，用户各种信息等
  // const token = localStorage.getItem('userToken') ? localStorage.getItem('userToken') : '';
  // 定义放行页面地址
  // eslint-disable-next-line no-unused-vars
  // 这里必须加上to.path !== 'login'的判断，不然会陷入无限循环，
  // 因为逻辑是第一次进来，判断用户信息不存在，即!user为true，由于使用的是next('/login')而非next()，
  // 会再次进入路由跳转，next()方法没有参数是直接进入页面，不会再次进入路由拦截，有参数则会，因为跳转，
  // 所以再次进入路由，再次判断，再次进入路由，再次判断，循环往复无限循环
  // 所以一定要加to.path !== 'login'的判断
  // 放行
  // if(to.path === '/cooperationCases' || to.path ==='/contactUs'){
  //   next();
  //   return;
  // }
  // if (!token && to.path !== '/index' ) {
  //      next({
  //         path:'/index'
  //     })
  //   // store.commit("setLoginShow",true);
  // }
  // else if (to.meta.permission) {
  //   user.permission === to.meta.permission ? next() :
  //       this.$message({
  //         type: 'error',
  //         message: '没有权限!'
  //       });
  // }
  //   else {
    next();
  // }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
