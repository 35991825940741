<template>
  <div style="width: 100%;">
    <el-input
        placeholder="请输入手机号"
        clearable
        prefix-icon="el-icon-mobile-phone"
        style="margin-top: 7%;margin-bottom: 7%;"
        v-model="input">
    </el-input>
    <el-input
        placeholder="请输入密码"
        prefix-icon="el-icon-lock"
        show-password
        type=password
        v-model="input2">
    </el-input>
    <el-input
        placeholder="请再次输入密码"
        prefix-icon="el-icon-lock"
        show-password
        style="margin-top: 7%;"
        type=password
        v-model="input3">
    </el-input>
    <el-input
        placeholder="请输入验证码"
        clearable
        style="margin-top: 7%;margin-bottom: 7%;"
        @change="change"
        v-model="input4">
<!--      <i slot="prefix" class="el-input__icon el-icon-lock" ></i>-->
      <img  slot="prefix" src="../../assets/img/yzm.svg" style="width: 18px;height: 18px;margin-top: 60%;opacity: 0.6">
      <div slot="suffix" class="yzm" v-if="show===0" @click="yzm">获取验证码</div>
      <div slot="suffix" class="yzm" style="cursor: auto;color: #818080" v-if="show===1">已发送({{ testTime }}s)</div>
    </el-input>
  </div>
</template>

<script>
export default {
  name: "wJmM",
  data(){
    return{
      input:'',
      input2:'',
      input3:'',
      input4:'',
      testTime:60,
      timer:null,
      show:0
    }
  },
  methods:{
    change(){
      this.$emit('wJmM')
    },
    yzm(){
      console.log('获取验证码')
    },
    computedTestTime() {
      let _this = this;

      _this.timer=setInterval(function (){


        _this.testTime--
        console.log(_this.testTime)
        if (_this.testTime===-1){
          clearInterval(_this.timer)
          _this.show=0
          _this.testTime=60
        }
      },1000)
    },
  },
  beforeDestroy(){
    clearInterval(this.timer)
  }
}
</script>

<style scoped>
::v-deep .el-input__inner{
  border-radius: 4px !important;
}
.yzm{
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 0.2rem;
  color: #3291FF;
  margin-right:0.2rem;
  width: 100%;cursor: pointer
}
::v-deep .el-input__suffix-inner{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}
</style>
