<template>
  <div id="app" style="overflow: hidden">
    <router-view/>
  </div>
</template>

<script>
// 禁止缩放
// window.addEventListener('mousewheel', function (event) {
//   if (event.ctrlKey === true || event.metaKey) {
//     event.preventDefault();
//   }
// }, { passive: false });
// 禁止使用右键、F12
// document.onkeydown = () => {
//   //禁用F12
//   if (window.event && window.event.keyCode == 123) {
//     return false;
//     //禁用ctrl+shift+i,
//   } else if (window.event.ctrlKey && window.event.shiftKey && window.event.keyCode == 73) {
//     return false;
//     //屏蔽Shift+F10
//   } else if (window.event.shiftKey && window.event.keyCode == 121) {
//     return false;
//   }
// };
// // 禁用右键
// document.oncontextmenu = new Function("event.returnValue=false");

import {allUrl} from "@/service/request";
import {mapMutations, mapState} from "vuex";
export default {
  name: 'app',
  components: {

  },
  mounted() {
    console.log("app vue");
    const token = window.localStorage.getItem("userToken");
    console.log(token)
    if(token!==""){
      this.getUserInfo();
    }

  },
  metaInfo() {
    return {
      title: this.$store.state.settings.dynamicTitle && this.$store.state.settings.title,
      titleTemplate: title => {
        return title ? `${title} - ${process.env.VUE_APP_TITLE}` : process.env.VUE_APP_TITLE
      }
    }
  },
  computed:{
    ...mapState(['headPath','loginOrHeadShow','userPhone'])
  },
  methods:{
    ...mapMutations(['setHeadPath','setLoginOrHeadShow','setUserPhone']),
    // 获取用户信息
    getUserInfo(){
      console.log("获取用户信息");
      this.request.get("/user/info", {
        params: {
          token:window.localStorage.getItem("userToken")
        }
      }).then(res => {
        console.log(res)
        if(res.status=="success"){
          console.log("==========================");
          console.log(res.data.info);
          this.$store.state.info = res.data.info;
          // 用户信息
          if(res.data.info.head_img_path===null){
            this.setHeadPath("https://jktcloud.oss-cn-hangzhou.aliyuncs.com/head.png");
          }else{
            this.setHeadPath(allUrl.picUrl+res.data.info.head_img_path);
          }
          this.setLoginOrHeadShow(0);
          this.setUserPhone(res.data.info.tel);
          // this.setHeadPath(allUrl.picUrl+res.data.info.head_img_path);
        }else{
          window.localStorage.removeItem("userToken")
        }
      })
    },
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
