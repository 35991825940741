<template>
  <div class="home">
    <top-page @cli="cli" style="height: 1rem"></top-page>
    <router-view/>
  </div>
</template>

<script>
// @ is an alias to /src


import TopPage from "@/components/topPage";
// import LunboPage from "@/components/lunboPage";
export default {
  name: 'HomeView',
  components: {
    // LunboPage,
    TopPage

  },
  methods:{
    cli(val){
      switch (val) {
        case 0 :
          this.$router.push({
            path:'/index'
          })
              break
        case 1:
          this.$router.push({
            path:'/courseCenter'
          })
              break
        case 2:
          this.$router.push({
            path:'/onlineTest'
          })
              break
        case 3:
          this.$router.push({
            path:'/onlinePractice'
          })
              break
        case 4:
          this.$router.push({
            path:'/cooperationCases'
          })
              break
        case 5:
          this.$router.push({
            path:'/appDownload'
          })
              break
        case 6:
          this.$router.push({
            path:'/contactUs'
          })
              break
      }
    }
  }
}
</script>
<style scoped>
.home{
  height: 100%;
  width: 100%;
  min-width: 1515px;
  min-height: 630px;
  display: flex;
  flex-direction: column;
}

</style>
