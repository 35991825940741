import axios from 'axios'
//创建axios实例，简写代码
// axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// multipart/form-data
const request = axios.create({
    // `baseURL` 将自动加在 `url` 前面，除非 `url` 是一个绝对 URL。npm
    // 它可以通过设置一个 `baseURL` 便于为 axios 实例的方法传递相对 URL
    //   正式
    // baseURL: 'https://jkt.admin.lanshuo.org.cn/api/client',  // 注意！！ 这里是全局统一加上了 '/api' 前缀，也就是说所有接口都会加上'/api'前缀在，页面里面写接口的时候就不要加 '/api'了，否则会出现2个'/api'，类似 '/api/api/user'这样的报错，切记！！！
    // 测试
      baseURL: 'https://jkt.test.lanshuo.org.cn/api/client',  // 注意！！ 这里是全局统一加上了 '/api' 前缀，也就是说所有接口都会加上'/api'前缀在，页面里面写接口的时候就不要加 '/api'了，否则会出现2个'/api'，类似 '/api/api/user'这样的报错，切记！！！
    // baseURL: 'http://192.168.110.244:8989/api/client',  // 注意！！ 这里是全局统一加上了 '/api' 前缀，也就是说所有接口都会加上'/api'前缀在，页面里面写接口的时候就不要加 '/api'了，否则会出现2个'/api'，类似 '/api/api/user'这样的报错，切记！！！
    // baseURL: 'http://192.168.110.244:9090/api/client',  // 注意！！ 这里是全局统一加上了 '/api' 前缀，也就是说所有接口都会加上'/api'前缀在，页面里面写接口的时候就不要加 '/api'了，否则会出现2个'/api'，类似 '/api/api/user'这样的报错，切记！！！
    // baseURL: 'http://192.168.10.4:8989/api/client',  // 注意！！ 这里是全局统一加上了 '/api' 前缀，也就是说所有接口都会加上'/api'前缀在，页面里面写接口的时候就不要加 '/api'了，否则会出现2个'/api'，类似 '/api/api/user'这样的报错，切记！！！
    // `timeout` 指定请求超时的毫秒数(0 表示无超时时间)
    // 如果请求花费了超过 `timeout` 的时间，请求将被中断
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json;charset=utf-8'
    }
})

// 上传用的接口
export const requestUpload = axios.create({
    // `baseURL` 将自动加在 `url` 前面，除非 `url` 是一个绝对 URL。npm
    // 它可以通过设置一个 `baseURL` 便于为 axios 实例的方法传递相对 URL
    //   正式
    // baseURL: 'https://jkt.admin.lanshuo.org.cn/api/client',  // 注意！！ 这里是全局统一加上了 '/api' 前缀，也就是说所有接口都会加上'/api'前缀在，页面里面写接口的时候就不要加 '/api'了，否则会出现2个'/api'，类似 '/api/api/user'这样的报错，切记！！！
    // 测试
    baseURL: 'https://jkt.test.lanshuo.org.cn/api/client',  // 注意！！ 这里是全局统一加上了 '/api' 前缀，也就是说所有接口都会加上'/api'前缀在，页面里面写接口的时候就不要加 '/api'了，否则会出现2个'/api'，类似 '/api/api/user'这样的报错，切记！！！
    // baseURL: 'https://jkt.admin.lanshuo.org.cn/api/client',  // 注意！！ 这里是全局统一加上了 '/api' 前缀，也就是说所有接口都会加上'/api'前缀在，页面里面写接口的时候就不要加 '/api'了，否则会出现2个'/api'，类似 '/api/api/user'这样的报错，切记！！！
    // baseURL: 'http://192.168.110.244:8989/api/client',  // 注意！！ 这里是全局统一加上了 '/api' 前缀，也就是说所有接口都会加上'/api'前缀在，页面里面写接口的时候就不要加 '/api'了，否则会出现2个'/api'，类似 '/api/api/user'这样的报错，切记！！！
    // baseURL: 'http://192.168.110.244:9090/api/client',  // 注意！！ 这里是全局统一加上了 '/api' 前缀，也就是说所有接口都会加上'/api'前缀在，页面里面写接口的时候就不要加 '/api'了，否则会出现2个'/api'，类似 '/api/api/user'这样的报错，切记！！！
    // baseURL: 'http://192.168.10.4:8989/api/client',  // 注意！！ 这里是全局统一加上了 '/api' 前缀，也就是说所有接口都会加上'/api'前缀在，页面里面写接口的时候就不要加 '/api'了，否则会出现2个'/api'，类似 '/api/api/user'这样的报错，切记！！！
    // `timeout` 指定请求超时的毫秒数(0 表示无超时时间)
    // 如果请求花费了超过 `timeout` 的时间，请求将被中断
    timeout: 60000,
    headers: {
        'Content-Type': 'multipart/form-data'
    }
})

// eslint-disable-next-line no-unused-vars
export const allUrl = {
    picUrl: 'https://oss.lanshuo.org.cn/',
    baseUrl: 'https://jkt.admin.lanshuo.org.cn',
    apiUrl: 'https://jkt.admin.lanshuo.org.cn/api/app',
    // 可试看视频地址
    // verificationVideoUrl: 'http://verification.lanshuo.org.cn/',
    verificationVideoUrl: 'https://oss.lanshuo.org.cn/',
    // 无限制查看视频全部内容地址
    ossVideoUrl: 'https://oss.lanshuo.org.cn/',
    // picUrl: 'http://jkt.lanshuo.org.cn/',
}

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(
    config => {
        if (window.localStorage.getItem('userToken')) {
            config.headers['token'] = window.localStorage.getItem('userToken');
            // config.headers.token=window.localStorage.getItem('userToken');
        }
        return config
        //   // 设置请求头

    }, error => {
        return Promise.reject(error)
    });

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        let res = response.data;
        // 如果是返回的文件
        //Blob(Binary long Object)是二进制长对象的意思,Blob通常用于存储大文件,典型的Blob内容是一张图片或者一个声音文件
        if (response.config.responseType === 'blob') {
            return res
        }
        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        return res;
    },
    error => {
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)

requestUpload.interceptors.request.use(
    config => {
        if (window.localStorage.getItem('userToken')) {
            config.headers['token'] = window.localStorage.getItem('userToken');
            // config.headers.token=window.localStorage.getItem('userToken');
        }
        return config
        //   // 设置请求头

    }, error => {
        return Promise.reject(error)
    });

// response 拦截器
// 可以在接口响应后统一处理结果
requestUpload.interceptors.response.use(
    response => {
        let res = response.data;
        // 如果是返回的文件
        //Blob(Binary long Object)是二进制长对象的意思,Blob通常用于存储大文件,典型的Blob内容是一张图片或者一个声音文件
        if (response.config.responseType === 'blob') {
            return res
        }
        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        return res;
    },
    error => {
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)

// 封装axios的post请求
// const post = (url, params) => {
//     return new Promise((resolve, reject) => {
//         axios
//             .post(url, params)
//             .then(response => {
//                 resolve(response.data);
//             })
//             .catch(error => {
//                 reject(error);
//             });
//     });
// };

export default request
