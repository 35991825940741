<template>
  <div style="width: 100%;">
    <el-input
        placeholder="请输入手机号"
        prefix-icon="el-icon-mobile-phone"
        clearable
        style="margin-top: 10%;margin-bottom: 10%;"
        v-model="input">
    </el-input>
    <el-input
        placeholder="请输入验证码"
        clearable
        @change="change"
        v-model="input2">
<!--      <i slot="prefix" class="el-input__icon el-icon-lock"></i>-->
      <img  slot="prefix" src="../../assets/img/yzm.svg" style="width: 18px;height: 18px;margin-top: 60%;opacity: 0.6">
      <div slot="suffix" class="yzm" v-if="show===0" @click="yzm">获取验证码</div>
      <div slot="suffix" class="yzm" style="cursor: auto;color: #818080" v-if="show===1">已发送({{ testTime }}s)</div>

    </el-input>
  </div>
</template>

<script>
export default {
  name: "sJyZ",
  data(){
    return{
      input:'',
      input2:'',
      testTime:60,
      timer:null,
      show:0
    }
  },
  methods:{
    change(){
      this.$emit('sJyZ')
    },
    yzm(){
      console.log('登录获取验证码')
      if(this.input===""){
        this.$message({
          type: 'warning',
          message: '请先输入手机号。'
        });
        return;
      }
      this.request.get("/user/sendCode", {
        params: {
          telPhone:this.input,
          SMSType:"userLogin"
        }
      }).then(res => {
        if(res.status==="success"){
          this.$message({
            type: 'success',
            message: '验证码发送成功。'
          });
          this.show=1
          this.computedTestTime()
        }else{
          this.$message({
            type: 'error',
            message: '验证码发送失败。'
          });
        }
      })
    },
    computedTestTime() {
      let _this = this;

      _this.timer=setInterval(function (){


        _this.testTime--
        console.log(_this.testTime)
        if (_this.testTime===-1){
          clearInterval(_this.timer)
          _this.show=0
          _this.testTime=60
        }
      },1000)
    },
  },
  beforeDestroy(){
    clearInterval(this.timer)
  }
}
</script>

<style scoped>
::v-deep .el-input__inner{
  border-radius: 4px !important;
}
.yzm{
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 0.2rem;
  color: #3291FF;
  margin-right:0.2rem;
  width: 100%;cursor: pointer
}
::v-deep .el-input__suffix-inner{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}
</style>
