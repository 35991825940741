import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    // 登录页显示
    loginShow:false,
    // 显示头像
    headPath:"" || "https://jktcloud.oss-cn-hangzhou.aliyuncs.com/head.png",
    info:{},
    // 0头像显示，1显示登录按钮
    loginOrHeadShow:1,
    userPhone:'',
  },
  mutations:{
    // 登录弹窗
    setLoginShow(state,loginShow){
      state.loginShow=loginShow;
    },
    // 头像
    setHeadPath(state,headPath){
      state.headPath=headPath;
    },
    // 显示登录按钮还是头像
    setLoginOrHeadShow(state,loginOrHeadShow){
      state.loginOrHeadShow=loginOrHeadShow;
    },
    setUserPhone(state,userPhone){
      state.userPhone=userPhone;
    },

  },
  modules: {
  }
})
