<template>
  <div style="width: 100%;">
    <el-input
        placeholder="请输入手机号"
        prefix-icon="el-icon-mobile-phone"
        clearable
        style="margin-top: 10%;margin-bottom: 10%;"
        v-model="phoneInput">
    </el-input>
    <el-input
        placeholder="请输入密码"
        prefix-icon="el-icon-lock"
        type=password
        show-password
        @change="change"
        v-model="passwordInput">
    </el-input>
  </div>
</template>

<script>
export default {
  name: "ZHmM",
  data(){
    return{
      phoneInput:'',
      passwordInput:''
      // phoneInput:'18350095092',
      // phoneInput:'18965071517',
      // phoneInput:'13600824009',
      // passwordInput:'123456',
      }
  },
  methods:{
    change(){
      this.$emit('ZHmM')
      // console.log('123')
    }
  }
}
</script>

<style scoped>
::v-deep .el-input__inner{
  border-radius: 4px !important;
}
</style>
